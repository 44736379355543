import API from '..';
import { auth } from '../entpoints';

export const authenticate = async (employee_id: string, password?: string) => {
  try {
    const data = {
      employee_id,
      password,
    };
    const res = await API.post(auth.login, data);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const userlookup = async (employee_id: string) => {
  try {
    const data = {
      employee_id,
    };
    const res = await API.post(auth.userlookup, data);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const authMe = async () => {
  try {
    const res = await API.get(auth.me);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setPassword = async (
  employee_id: string,
  password: string,
  confirm_password: string
) => {
  try {
    const data = {
      employee_id,
      password,
      confirm_password,
    };
    const res = await API.post(`${auth.set_password}/${employee_id}`, data);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const findUser = async (pan_number: string, employee_id: string) => {
  try {
    const data = {
      pan_number,
      employee_id,
    };
    const res = await API.post(auth.find_user, data);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const resetPassword = async (
  pan_number: string,
  password: string,
  confirm_password: string,
  employee_id: string
) => {
  try {
    const data = {
      pan_number,
      password,
      confirm_password,
      employee_id,
    };
    const res = await API.post(auth.reset_password, data);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};
