import React from 'react';
import { useSelector } from 'react-redux';

import { CheckCircleRounded } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';

import { RootState } from 'src/store/store';

const AlertBanner = () => {
  const { title, message, type } = useSelector((store: RootState) => store.alerts);

  return title ? (
    <div>
      <Alert
        variant="outlined"
        icon={false}
        severity={type}
        iconMapping={{
          success: <CheckCircleRounded fontSize="inherit" />,
        }}
        sx={{ alignItems: 'center', padding: 2, borderWidth: 2 }}
      >
        <Stack>
          <Stack>
            <Typography mb={0.5} variant="h6" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
          </Stack>
          <Typography>{message}</Typography>
        </Stack>
      </Alert>
    </div>
  ) : null;
};

export default AlertBanner;
