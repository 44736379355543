/* eslint-disable */

export const calculateGoldAmount = (
  purity: number,
  goldRates: { '24k': number; '22k': number; '21k-17k': number; '17k-14k': number },
  netWeight: number
) => {
  switch (true) {
    case purity >= 99.99:
      return netWeight * goldRates['24k'];

    case purity >= 95.82:
      return (netWeight * goldRates['24k'] * purity) / 100;
    case purity >= 91.6:
      return netWeight * goldRates['22k'];
    case purity >= 74.99:
      return (netWeight * goldRates['21k-17k'] * purity) / 100;
    case purity >= 58.33:
      return (netWeight * goldRates['17k-14k'] * purity) / 100;
  }
};

export const calculateGoldAmountByRange = (
  range: number,
  purity: number,
  goldRates: number,
  netWeight: number
) => {
  switch (true) {
    case range >= 24:
      if (purity == 99.99) {
        return (netWeight * goldRates).toFixed(2);
      } else {
        return ((netWeight * goldRates * purity) / 100).toFixed(2);
      }

    // Hall Mark
    case range == 22.1:
      if (purity == 91.6) {
        return (netWeight * goldRates).toFixed(2);
      } else {
        return ((netWeight * goldRates * purity) / 100).toFixed(2);
      }

    // Non Hall Mark
    case range >= 22:
      if (purity == 91.6) {
        return (netWeight * goldRates).toFixed(2);
      } else {
        return ((netWeight * goldRates * purity) / 100).toFixed(2);
      }

    case range >= 17:
    case range >= 14:
      return ((netWeight * goldRates * purity) / 100).toFixed(2);
    default:
      return '0.00'; // Return '0.00' or handle other cases as needed
  }
};

export const calculateGoldKarat = (purity: number) => {
  if (purity >= 99) {
    return 24; // 24K for purity >= 99.99%
  } else if (purity >= 95.82) {
    return 23; // 23K for purity >= 95.82%
  } else if (purity >= 91.6) {
    return 22.1; // 22K for purity >= 91.6% (HM)
  } else if (purity >= 87.49) {
    return 21; // 21K for purity >= 87.49%
  } else if (purity >= 83.33) {
    return 20; // 20K for purity >= 83.33%
  } else if (purity >= 79.16) {
    return 19; // 19K for purity >= 79.16%
  } else if (purity >= 74.99) {
    return 18; // 18K for purity >= 74.99%
  } else if (purity >= 70.83) {
    return 17; // 17K for purity >= 70.83%
  } else if (purity >= 66.66) {
    return 16; // 16K for purity >= 66.66%
  } else if (purity >= 62.49) {
    return 15; // 15K for purity >= 62.49%
  } else if (purity >= 58.33) {
    return 14; // 14K for purity >= 58.33%
  }
};

export const calculateGoldPurity = (karat: any) => {
  if (karat === 24) {
    return 99.99; // 99% purity for 24K
  } else if (karat === 23) {
    return 95.82; // 95.82% purity for 23K
  } else if (karat === 22) {
    return 91.6; // 91.6% purity for 22K
  } else if (karat === 21) {
    return 87.49; // 87.49% purity for 21K
  } else if (karat === 20) {
    return 83.33; // 83.33% purity for 20K
  } else if (karat === 19) {
    return 79.16; // 79.16% purity for 19K
  } else if (karat === 18) {
    return 74.99; // 74.99% purity for 18K
  } else if (karat === 17) {
    return 70.83; // 70.83% purity for 17K
  } else if (karat === 16) {
    return 66.66; // 66.66% purity for 16K
  } else if (karat === 15) {
    return 62.49; // 62.49% purity for 15K
  } else if (karat === 14) {
    return 58.33; // 58.33% purity for 14K
  } else {
    return null; // Return null if karat value doesn't match
  }
};

export const calculateTotalAmount = (
  existingFinalAmount: string,
  goldAmount: number,
  serviceCharge: number
) => {
  // Initialize default values
  let totalAmount = 0;
  let serviceChargeAmount = 0;
  let finalAmount = 0;

  // Check if watchGoldAmount is 'N/A'
  if (existingFinalAmount === 'N/A') {
    // Calculate serviceChargeAmount based on goldAmount and watchServiceCharge
    serviceChargeAmount = goldAmount * (serviceCharge / 100);
    finalAmount = goldAmount - serviceChargeAmount;

    return {
      serviceChargeAmount,
      finalAmount: finalAmount,
    };
  }
  // Get the previous sum amount
  const parsedGoldAmount = parseFloat(existingFinalAmount);
  const getPreviousSumAmount = parsedGoldAmount * (serviceCharge / 100) + parsedGoldAmount;
  totalAmount = getPreviousSumAmount + parseFloat(goldAmount + '');
  serviceChargeAmount = totalAmount * (serviceCharge / 100);
  finalAmount = totalAmount - serviceChargeAmount;

  return {
    serviceChargeAmount,
    finalAmount,
  };
};

export const calculateNetWeight = (
  grossWeight: number = 0,
  stoneWeight: number = 0,
  wastage: number = 0
) => {
  return grossWeight - stoneWeight - wastage;
};

export const getGoldRatesByRange = (
  range: number,
  _24k: string,
  _22k: string,
  _21k_17k: string,
  _17k_14k: string
) => {
  switch (true) {
    case range >= 23:
      return _24k;
    case range == 22.1:
      return _22k;
    case range >= 22:
      return Number(_22k) - 100;

    case range > 17:
      return _21k_17k;
    case range >= 14:
      return _17k_14k;
  }
};

export const getPurityRange = (range: number) => {
  switch (true) {
    case range >= 24:
      return '24k';
    case range >= 22:
      return '22k';
    case range >= 17:
      return '21k-17k';
    case range >= 14:
      return '17k-14k';
  }
};

export const restrictToTwoDecimals = (event: React.FormEvent<HTMLInputElement>) => {
  const input = event.currentTarget;
  const { value } = input;

  // Allow only up to 2 decimal places
  if (value.includes('.')) {
    const parts = value.split('.');
    if (parts[1].length > 2) {
      input.value = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
  }
};