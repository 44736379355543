// Assuming RegionData is the type of data needed to create a region
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPaymentApprovals,
  getReleaseApprovals,
  handleSalesApproval,
  getGoldRateApprovals,
  getNegotiationApprovals,
  getServiceChargeApprovals,
  handleReleasePaymentApproval,
} from 'src/apis/sales';

interface SalesState {
  loading: boolean;
  records: [];
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  currentTransaction: any;
}

const initialState: SalesState = {
  loading: false,
  records: [],
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
  currentTransaction: null,
};

/* eslint-disable no-useless-catch */

export const fetchReleaseApprovals = createAsyncThunk(
  'sales/fetch-records',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const releaseApprovals = await getReleaseApprovals(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return releaseApprovals;
  }
);

export const fetchNegotiationApprovals = createAsyncThunk(
  'sales/fetch-negotiations',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const estimation = await getNegotiationApprovals(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return estimation;
  }
);

export const fetchGoldRateApprovals = createAsyncThunk(
  'sales/fetch-gold-rate-approvals',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const estimation = await getGoldRateApprovals(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return estimation;
  }
);

export const fetchServiceChargeApprovals = createAsyncThunk(
  'sales/fetch-service-charge-approvals',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const estimation = await getServiceChargeApprovals(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return estimation;
  }
);

export const fetchPaymentApprovals = createAsyncThunk(
  'sales/fetch-payments-records',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const paymentApprovals = await getPaymentApprovals(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return paymentApprovals;
  }
);

export const salesApproval = createAsyncThunk(
  'sales/sales-approval',
  async ({
    remark,
    approved,
    transactionId,
  }: {
    remark: string | null;
    approved: boolean;
    transactionId: string;
  }) => {
    const resp = await handleSalesApproval(remark, approved, transactionId);
    return resp;
  }
);

export const salesPaymentApproval = createAsyncThunk(
  'sales/sales--payment-approval',
  async ({
    remark,
    approved,
    transactionId,
  }: {
    remark: string | null;
    approved: boolean;
    transactionId: string;
  }) => {
    const resp = await handleReleasePaymentApproval(remark, approved, transactionId);
    return resp;
  }
);

const salesSlice = createSlice({
  name: 'Sales',
  initialState,
  reducers: {
    setCurrentTransactions: (state, action) => {
      state.currentTransaction = action.payload;
    },
    resetRecords: (state) => {
      state.records = [];
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // Negotiation Approvals
    builder.addCase(fetchNegotiationApprovals.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchNegotiationApprovals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchNegotiationApprovals.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    // Service charge Approvals
    builder.addCase(fetchServiceChargeApprovals.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchServiceChargeApprovals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchServiceChargeApprovals.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    builder.addCase(fetchGoldRateApprovals.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchGoldRateApprovals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchGoldRateApprovals.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    builder.addCase(fetchReleaseApprovals.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchReleaseApprovals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchReleaseApprovals.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });

    builder.addCase(salesApproval.fulfilled, (state, action) => {
      state.currentTransaction = action.payload.release;
    });

    builder.addCase(salesPaymentApproval.fulfilled, (state, action) => {
      state.currentTransaction = action.payload.release;
    });

    //----

    builder.addCase(fetchPaymentApprovals.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchPaymentApprovals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchPaymentApprovals.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });
  },
});

export const { setCurrentTransactions, resetRecords } = salesSlice.actions;
export default salesSlice.reducer;
