import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import API from 'src/apis';
import cookies from 'src/apis/cookies';
import { RootState, AppDispatch } from 'src/store/store';
import { getUserInfo } from 'src/store/slices/user-slice';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const { loading } = useSelector((state: RootState) => state.user);
  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const returnTo = searchParams.get('returnTo') || paths.dashboard.root;
  const { user } = useSelector((state: RootState) => state.user);

  const validateToken = async () => {
    try {
      await dispatch(getUserInfo()).unwrap();
      return router.replace(returnTo);
    } catch (error) {
      cookies.removeCookie('wg-token');
      return null;
    }
  };

  const check = useCallback(async () => {
    const token = cookies.getCookies('wg-token');
    if (!token) {
      return;
    }
    API.setAccessToken(token);
    await validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
