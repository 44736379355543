import React from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface Requirement {
  re: RegExp;
  label: string;
}

const requirements: Requirement[] = [
  { re: /.{8}/, label: 'At least 8 characters' },
  { re: /[0-9]/, label: 'At least 1 number' },
  { re: /[a-z]/, label: 'At least 1 lower case character' },
  { re: /[A-Z]/, label: 'At least 1 upper case character' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'At least 1 special character' },
];

interface PasswordRequirementProps {
  requirement: Requirement;
  isMet: boolean;
}

const PasswordRequirement: React.FC<PasswordRequirementProps> = ({ requirement, isMet }) => (
  <ListItem>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isMet ? (
        <CheckIcon style={{ color: 'green', marginRight: '8px' }} />
      ) : (
        <CloseIcon style={{ color: 'red', marginRight: '8px' }} />
      )}
      <ListItemText primary={requirement.label} />
    </Box>
  </ListItem>
);

interface PasswordValidationPopupProps {
  password: string;
}

const PasswordValidationPopup: React.FC<PasswordValidationPopupProps> = ({ password }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const getWidth = () => {
    if (isSmallScreen) {
      return '200px';
    }
    if (isMediumScreen) {
      return '250px';
    }
    if (isLargeScreen) {
      return '300px';
    }
    return '270px';
  };  

  const StyledList = styled(List)(({ theme: localTheme }) => ({
    width: getWidth(),
    [localTheme.breakpoints.down('sm')]: {
      width: '200px',
    },
    [localTheme.breakpoints.between('sm', 'md')]: {
      width: '250px',
    },
    [localTheme.breakpoints.up('md')]: {
      width: '300px',
    },
  }));

  return (
    <StyledList>
      {requirements.map((req, index) => (
        <PasswordRequirement key={index} requirement={req} isMet={req.re.test(password)} />
      ))}
    </StyledList>
  );
};

interface CustomTooltipProps extends TooltipProps {
  className?: string;
  showPopup: boolean;
}

const CustomTooltip = styled(({ className, showPopup, ...props }: CustomTooltipProps) =>
  showPopup ? <Tooltip {...props} classes={{ popper: className }} /> : props.children
)(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    color: '#333',
    fontSize: '1em',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
}));

export { CustomTooltip, PasswordValidationPopup };
