/* eslint-disable */

import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Components
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';
import { paths } from 'src/routes/paths';
import Label from 'src/components/label';

import { goldAssayerStatus, releaseSalesHeadStatus, salesHeadStatus, salesStatus } from 'src/datas';

import PageTitle from 'src/components/page-title';
import {
  getNegotiationsCount,
  getPledgeEstimationsCount,
  getReleaseApprovalsCount,
} from 'src/apis/sales';
import ReleaseApprovalsTable from '../tables/release-approvals';
import PaymentApprovalTable from '../tables/payment-approval';
import { toast } from 'react-hot-toast';
import NegotiationApprovalTable from '../tables/negotiation-approval';

export type IUserTableFilters = {
  name: string;
  status: string;
};

type TransactionCount = {
  [key: string]: number;
};

interface HeaderProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const RenderTabs = () => {
  // default state filters
  const defaultFilters: IUserTableFilters = {
    name: '',
    status: 'ALL',
  };

  // States
  const [filters, setFilters] = useState(defaultFilters);
  const [transactionCount, setTransactionsCount] = useState<TransactionCount>({});

  // fetch all transactions count
  const fetchCounts = async () => {
    try {
      const counts = await getNegotiationsCount();
      setTransactionsCount(counts);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // useffect
  useEffect(() => {
    fetchCounts();
  }, [filters]);

  // updated values
  const onChangeTab = (name: string, value: any) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // On switch tab handler
  const handleFilterStatus = useCallback((event: React.SyntheticEvent, newValue: string) => {
    onChangeTab('status', newValue);
  }, []);

  const renderTable = () => {
    switch (filters.status) {
      case 'ALL':
        return <NegotiationApprovalTable status="" />;
      case 'PENDING':
        return <NegotiationApprovalTable status="PENDING" />;
      case 'APPROVED':
        return <NegotiationApprovalTable status="APPROVED" />;

      case 'REJECTED':
        return <NegotiationApprovalTable status="REJECTED" />;
      default:
        return null; // Return null if no specific case matches
    }
  };

  return (
    <Card>
      <Tabs
        value={filters.status}
        onChange={handleFilterStatus}
        sx={{
          px: 2.5,
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {Object.values(releaseSalesHeadStatus).map((tab) => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            sx={{ fontSize: 14 }}
            icon={
              <Label variant={(tab.value === filters.status && 'filled') || 'soft'}>
                {transactionCount[tab.value] || 0}
              </Label>
            }
          />
        ))}
      </Tabs>
      {renderTable()}
    </Card>
  );
};

// Header component
const RenderHeader = () => {
  return (
    <CustomBreadcrumbs
      heading="Negotiation Approvals"
      links={[
        { name: 'Dashboard', href: paths.dashboard.root },
        { name: 'Negotiation Approvals', href: paths.dashboard.negotiationApprovals },
      ]}
      sx={{
        mb: { xs: 3, md: 3 },
      }}
    />
  );
};

// Main render function
function NegotiationApprovalSection() {
  return (
    <>
      <PageTitle title="Negotiations" />
      <RenderHeader />
      <RenderTabs />
    </>
  );
}

export default NegotiationApprovalSection;
