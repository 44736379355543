import React from 'react';

import { Typography } from '@mui/material';

import formatINR from 'src/utils/format-inr';

const GoldRateDiffChecker = ({
  oldRate,
  newRate,
}: {
  oldRate: string | number | undefined;
  newRate: string | number | undefined;
}) => (

  // {
  //   InputProps: {
  //     endAdornment: (
  //       <InputAdornment position="end">
  //         {newRate ? (
  //           <Tooltip
  //             title={
  //               <div style={{ textAlign: 'center' }}>
  //                 The previous gold rate was <br />₹{oldRate}/per gram
  //               </div>
  //             }
  //           >
  //             <IconButton>
  //               <Iconify icon="tabler:refresh" />
  //             </IconButton>
  //           </Tooltip>
  //         ) : null}
  //       </InputAdornment>
  //     ),
  //   },
  // });

  <>
    {newRate ? (
      <Typography sx={{ fontSize: '12px', color: 'text.secondary', mt: 1 }}>
        The previous gold rate was ₹{formatINR(oldRate)}/per gram
      </Typography>
    ) : null}
  </>
);

export default GoldRateDiffChecker;
