import OneSignal from 'react-onesignal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';

import { useRouter } from 'src/routes/hooks';

import { AppDispatch } from 'src/store/store';
import { incrementNotificationCount } from 'src/store/notifications-slice';

import { notificationAlert } from 'src/components/notification';

const useOneSignal = () => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const router = useRouter();
  const navigate = useNavigate();

  const runOneSignal = async () => {
    if (!initialized) {
      await OneSignal.init({
        appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          actionMessage:
            'Please enable notifications to stay informed about ongoing business events and updates. ',
          autoPrompt: false,
        },
      })
        .then((e) => {
          setInitialized(true);
          OneSignal.Slidedown.promptPush();

          // Move event listener setup here to avoid duplicates
          OneSignal.Notifications.addEventListener('foregroundWillDisplay', (event: any) => {
            dispatch(incrementNotificationCount());
            notificationAlert(
              {
                title: event.notification.title,
                text: event.notification.body,
                event,
              },
              {},
              navigate
            );
          });
        })
        .catch((e) => console.log('Error configuring One Signal', e));
    }

    // handle notification messages

    // TODO : use this if the notifcations are not working
    // OneSignal.Debug.setLogLevel('trace');
  };

  useEffect(() => {
    runOneSignal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return null;
};

export default useOneSignal;
