/* eslint-disable */
import { TeleBasicFormValues } from 'src/interfaces';

import API from '..';
import { telesales,} from '../entpoints';

export const getTeleSalesCounts = async () => {
  try {
    const resp = await API.get(telesales.getCount);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createLead = async (body: TeleBasicFormValues) => {
  try{
    const resp = await API.post(telesales.createLead, body);
    return resp;
  } catch(error) {
    throw error.response.data.message;
  }
};

export const updateLead = async (lead_id: string, body: TeleBasicFormValues) => {
  try{
    const resp = await API.patch(`${telesales.createLead}/${lead_id}`, body);
    return resp;
  } catch(error) {
    throw error.response.data.message;
  }
};
export const mergeLead = async (lead_id: string, payload:any) => {
  try {
    const resp = await API.patch(`${telesales.updateCustomer}/${lead_id}/merge_lead`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const assignLead = async (searchText?: string) => {
  try {
    const url = searchText
      ? `${telesales.assignLeads}?role=TELE_SALES&search_text=${searchText}`
      : `${telesales.assignLeads}?role=TELE_SALES`;

    const resp = await API.get(url);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message );
  }
};
;

export const assignNewLead = async (call_id: any, payload: any) => {
  try {
    const resp = await API.patch(`${telesales.assignNewLeads}/${call_id}/assign`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getTelesalesFollowupCounts = async () => {
  try {
    const resp = await API.get(telesales.getFollowupCounts);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const selectBranch = async (searchText: string) => {
  try {
    const resp = await API.get(`${telesales.branchSelect}?search_text=${searchText}`);
    return resp;
  } catch (error: any) {
    throw new Error(error.response?.data?.message);
  }
};

export const getLeadById = async (leadId: string) => {
  try {
    const resp = await API.get(`${telesales.updateCustomer}/${leadId}`);
    return resp;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message);
  }
};


export const getCurrentLeads = async (searchText: string) => {
  try {
    const resp = await API.get(`${telesales.getCurrentLeads}?search_text=${searchText}`);
    return resp;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
export const outBoundLeadCall = async (lead_id: String) => {
  try {
    const resp = await API.post(`${telesales.updateCustomer}/${lead_id}/outbound_call`, {});
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to make outbound lead call');
  }
};

export const outBoundPendingLeadCall = async (payload: any) => {
  try {
    const resp = await API.post(`${telesales.updateCustomer}/outbound_call`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCallsById = async (call_id: any) => {
  try {
    const resp = await API.get(`${telesales.assignNewLeads}/${call_id}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const getTelesalesFollowups = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${telesales.getFollowups}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};
export const getTelesalesTransactions = async (
  status: string | null,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${telesales.getTransaction}?status=${status}&start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page_number=${page}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};
