import React from 'react';

import { Box, Typography } from '@mui/material';

const TotalCount: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Box>
      <Typography align="center" fontWeight={500} color="black" fontSize={14}>
        {children}
      </Typography>
    </Box>
  );

export default TotalCount;
