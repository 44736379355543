import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAllPayments,
  getReleasePayments,

} from 'src/apis/payments';

interface PaymentsState {
  loading: boolean;
  records: any[];
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  currentTransaction: any;
  paymentCounts: number;
  releasePaymentCounts: number;
  paymentDetail: any;
}

const initialState: PaymentsState = {
  loading: false,
  records: [],
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
  currentTransaction: null,
  paymentCounts: 0,
  releasePaymentCounts: 0,
  paymentDetail: null,
};
/* eslint-disable no-useless-catch */

// Fetch all payments
export const fetchAllPayments = createAsyncThunk(
  'payments/fetchAllPayments',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const response = await getAllPayments({
      status,
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType,
    });
    return response;
  }
);


// Fetch release payments
export const fetchReleasePayments = createAsyncThunk(
  'payments/fetch-release-records',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
  }) => {
    const releaseApprovals = await getReleasePayments(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText
    );
    return releaseApprovals;
  }
);

const paymentsSlice = createSlice({
  name: 'Payments',
  initialState,
  reducers: {
    setCurrentTransactions: (state, action) => {
      state.currentTransaction = action.payload;
    },
    resetRecords: (state) => {
      state.records = [];
      state.pageSize = 10;
      state.totalRecords = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReleasePayments.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchReleasePayments.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchReleasePayments.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.pageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    })
      // Handle fetchAllPayments
      .addCase(fetchAllPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.records || [];
        state.totalRecords = action.payload.total_records;
      })
      .addCase(fetchAllPayments.rejected, (state) => {
        state.loading = false;
      })

  },
});

export const { setCurrentTransactions, resetRecords } = paymentsSlice.actions;
export default paymentsSlice.reducer;
