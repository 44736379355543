import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { paths } from 'src/routes/paths';

import { User, NavItem } from 'src/interfaces';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_pad'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_apps'),
  kyc: icon('ic_customer_kyc'),
  payment: icon('ic_payments'),
  single_user: icon('ic_single_user'),
  user_profile: icon('ic_user_profile_back'),
  pen: icon('ic_pen'),
  call: icon('ic_call'),
  missed_Call: icon('ic_missed_call'),
};

// ----------------------------------------------------------------------

// function to render nav item based on roles
export function useNavData() {
  const { user } = useSelector((store: User) => store.user);

  const data = useMemo(() => {
    const navItems: Record<string, NavItem[]> = {
      BRANCH: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Customers', path: paths.dashboard.customer, icon: ICONS.ecommerce },
            // { title: 'Region Managament', path: paths.dashboard.regions, icon: ICONS.order}
          ],
        },
      ],
      GOLD_ASSAYER: [
        {
          items: [
            { title: 'Estimations', path: paths.dashboard.estimations, icon: ICONS.dashboard },
          ],
        },
      ],

      OPERATIONS: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            {
              title: 'Quotations Approvals',
              path: paths.dashboard.operationsApprovals,
              icon: ICONS.kyc,
            },
          ],
        },
      ],

      SALES_HEAD: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            {
              title: 'Gold Rate Approvals',
              path: paths.dashboard.goldRateApprovals,
              icon: ICONS.order,
            },
            {
              title: 'Pledge Estimation Approvals',
              path: paths.dashboard.releaseApprovals,
              icon: ICONS.ecommerce,
            },
            {
              title: 'Release Payment Approvals',
              path: paths.dashboard.salesHeadPaymentApprovals,
              icon: ICONS.ecommerce,
            },

            {
              title: 'Live Gold Rates',
              path: paths.dashboard.goldrates,
              icon: ICONS.ecommerce,
            },
          ],
        },
      ],
      TELE_SALES: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Leads', path: paths.dashboard.leads, icon: ICONS.single_user },
            { title: 'Follow-Ups', path: paths.dashboard.followup, icon: ICONS.user_profile },
          ],
        },
      ],
      TELE_SALES_HEAD: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Leads', path: paths.dashboard.leads, icon: ICONS.single_user },
            { title: 'Follow-Ups', path: paths.dashboard.followup, icon: ICONS.user_profile },
          ],
        },
      ],

      SALES: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            {
              title: 'Negotiation Approvals',
              path: paths.dashboard.negotiationApprovals,
              icon: ICONS.ecommerce,
            },
            {
              title: 'Service Charge Approvals',
              path: paths.dashboard.serviceChargeApprovals,
              icon: ICONS.ecommerce,
            },
            // { title: 'Estimations', path: paths.dashboard.sales_team, icon: ICONS.ecommerce },
            {
              title: 'Pledge Estimation Approvals',
              path: paths.dashboard.releaseApprovals,
              icon: ICONS.ecommerce,
            },

            {
              title: 'Release Payment Approvals',
              path: paths.dashboard.salesHeadPaymentApprovals,
              icon: ICONS.ecommerce,
            },
          ],
        },
      ],
      KYC_MAKER: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Customers KYC', path: paths.dashboard.customersKyc, icon: ICONS.kyc },
          ],
        },
      ],
      KYC_CHECKER: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Customers KYC', path: paths.dashboard.customersKyc, icon: ICONS.kyc },
          ],
        },
      ],
      ACCOUNTS: [
        {
          items: [
            { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
            { title: 'Payments', path: paths.dashboard.accounts, icon: ICONS.payment },
            {
              title: 'Release Payments',
              path: paths.dashboard.accountsReleasePayments,
              icon: ICONS.payment,
            },
          ],
        },
      ],

      DEFAULT: [
        {
          items: [{ title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard }],
        },
      ],
    };

    return navItems[user.role] || navItems.DEFAULT;
  }, [user]);

  return data;
}
