// @ts-nocheck
/* eslint-disable */

import API from '..';
import { payments, transaction } from '../entpoints';

export const getAllPayments = async ({
  status,
  pageSize = 10,
  page = 1,
  startDate = '',
  endDate = '',
  searchText = '',
  transactionType = '',
}: {
  status: any;
  pageSize?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  searchText?: string;
  transactionType?: string;
}) => {
  try {
    const resp = await API.get(
      `${payments.getPayments}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'An error occurred while fetching KYC transactions.'
    );
  }
};

export const getPaymentCounts = async () => {
  try {
    const resp = await API.get(payments.getCounts);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getPaymentById = async (transaction_id: string) => {
  try {
    const resp = await API.get(`${payments.getPaymentsById}/${transaction_id}/payment`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const initiateFinalPayment = async (payload: {
  transaction_id: string;
  payment_type: string;
  amount: number;
}) => {
  try {
    const resp = await API.post(`${payments.getPayments}`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const downloadInvoice = async (transaction_id: string) => {
  try {
    const resp = await API.get(`${payments.getPaymentsById}/${transaction_id}/invoice/download`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getReleasePaymentCounts = async () => {
  try {
    const resp = await API.get(payments.getReleaseCounts);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getReleasePayments = async (
  status: string,
  pageSize = 10,
  page = 1,
  startDate = '',
  endDate = '',
  searchText = ''
) => {
  try {
    const resp = await API.get(
      `${payments.getReleasePayments}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const uploadReleasePaymentScreenshot = async (transactionId: string, payload: any) => {
  try {
    const form = new FormData();

    form.append('amount', payload.final_amount);
    form.append('ifsc_code', payload.ifsc_code);
    form.append('account_holder_name', payload.account_holder_name);
    form.append('bank_name', payload.bank_name);
    form.append('payment_screenshot', payload.payment_screenshot);
    form.append('account_number', payload.account_number);

    const resp = await API.post(
      `${payments.uploadReleasePaymentScreenshot}/${transactionId}/release_payment`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const zeroDifferencePayout = async(transaction_id: string) => {
  try {
    const resp = await API.patch(`${transaction.getTransactionById}/${transaction_id}/generate_invoice`);
    return resp;
  } catch(error) {
    throw new Error(error.response.data.message);
  }
}
