// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------
// TODO: handle routes here
export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

    // Branch
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/customer-walkin`,
    three: `${ROOTS.DASHBOARD}/customers`,
    regions: `${ROOTS.DASHBOARD}/regions`,
    customer: `${ROOTS.DASHBOARD}/customers`,
    estimations: `${ROOTS.DASHBOARD}/valuation/estimations`,
    quotations: `${ROOTS.DASHBOARD}/customers/quotations`,
    group: {
      root: `${ROOTS.DASHBOARD}/user`,
    },

    // Sales
    sales: `${ROOTS.DASHBOARD}/sales-head`,
    goldRateApprovals: `${ROOTS.DASHBOARD}/goldrate-approvals`,
    sales_team: `${ROOTS.DASHBOARD}/sales/estimations`,
    releaseApprovals: `${ROOTS.DASHBOARD}/release-approvals`,
    salesHeadPaymentApprovals: `${ROOTS.DASHBOARD}/payment-approvals`,
    negotiationApprovals: `${ROOTS.DASHBOARD}/negotiations`,
    serviceChargeApprovals: `${ROOTS.DASHBOARD}/service-charge-approvals`,
    goldrates: `${ROOTS.DASHBOARD}/goldrates`,

    // KYC
    customerKyc: `${ROOTS.DASHBOARD}/kyc-checker`,
    customerKycMaker: `${ROOTS.DASHBOARD}/kyc-maker`,
    customersKyc: `${ROOTS.DASHBOARD}/kyc/approvals`,

    // Accounts
    accounts: `${ROOTS.DASHBOARD}/accounts`,
    accountsReleasePayments: `${ROOTS.DASHBOARD}/accounts/release-payments`,
    releaseInvoice: `${ROOTS.DASHBOARD}/payments`,
    operationsApprovals: `${ROOTS.DASHBOARD}/quotations/approvals`,

    // Telesales
    telesales: `${ROOTS.DASHBOARD}/telesales`,
    lead: `${ROOTS.DASHBOARD}/telesales/lead`,
    leads: `${ROOTS.DASHBOARD}/telesales/leads`,
    followup: `${ROOTS.DASHBOARD}/telesales/followup`,
    missed_call: `${ROOTS.DASHBOARD}/telesales/missed_call`,
    takeover: `${ROOTS.DASHBOARD}/takeover`,
  },
};
