/* eslint-disable */

import { Id, toast, ToastOptions } from 'react-toastify';

import { Stack, Typography } from '@mui/material';

// Define the prop types for the Msg component
interface MsgProps {
  title: string;
  text: string;
  onClick?: () => void; // Optional click handler
  event: any;
}

const WgLogo = () => (
  <svg width="53" height="36" viewBox="0 10 313 486" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="156.5" cy="258" r="236" fill="white" />
    <circle cx="156.5" cy="258" r="218" fill="#002EA7" />
    <path
      d="M40 226L118.77 384L154.872 315.961M134.372 278.821L101.551 209.897L154.872 118L168 143.381M134.372 278.821L154.872 248.188M134.372 278.821L114.77 321.487L62.1 213.179M154.872 170.513L177.846 209.897L154.872 248.188M154.872 170.513L131.898 209.897L154.872 248.188M154.872 170.513L168 143.381M168 143.381L204.103 213.179L169.273 278.821M154.872 315.961L187.693 384L273.026 226.462H286.154M154.872 315.961L169.273 278.821M169.273 278.821L187.693 321.487L246.77 213.179"
      stroke="#ffff"
      strokeWidth="11"
    />
  </svg>
);

// Define the Msg component to be used in the toast notifications
export const Msg = ({ title, text, onClick }: MsgProps) => (
  <div role="button" tabIndex={0} className="msg-container" onClick={onClick}>
    <Stack direction="row" spacing={2}>
      <WgLogo />
      <div>
        <Typography fontSize={16} fontWeight={600} color="black">
          {title}
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </div>
    </Stack>
  </div>
);

// Default toast options
const defaultToastOptions: ToastOptions = {
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  progressStyle: { background: '#002EA7' },
};

// Main notificationAlert function with typed parameters
export const notificationAlert = (
  notificationProps: MsgProps,
  toastProps: ToastOptions = {},
  navigate?: (url: string) => void // Optional navigate function
): Id => {
  // const router = useRouter();
  const { title, text, event } = notificationProps;

  // const router = useRouter();

  // Define the click handler
  const handleClick = () => {
    if (event && event.notification && event.notification.launchURL) {
      const url = event.notification.launchURL;
      if (navigate) {
        if (url.startsWith('/')) {
          // Handle relative paths
          navigate(url);
        } else {
          // Handle absolute URLs or external links
          window.location.href = url;
        }
      }
    }
  };

  return toast(<Msg {...notificationProps} onClick={handleClick} />, {
    ...defaultToastOptions,
    ...toastProps,
  });
};

// Success variation of notificationAlert with typed parameters
notificationAlert.success = (notificationProps: MsgProps, toastProps: ToastOptions = {}): Id => {
  const { title } = notificationProps;

  // Define the click handler
  const handleClick = () => {
    console.log(`Success toast with title clicked: ${title}`);
  };

  return toast.success(<Msg {...notificationProps} onClick={handleClick} />, {
    ...defaultToastOptions,
    ...toastProps,
  });
};

// Error variation of notificationAlert with typed parameters
notificationAlert.error = (notificationProps: MsgProps, toastProps: ToastOptions = {}): Id => {
  const { title } = notificationProps;

  // Define the click handler
  const handleClick = () => {
    console.log(`Error toast with title clicked: ${title}`);
  };

  return toast.error(<Msg {...notificationProps} onClick={handleClick} />, {
    ...defaultToastOptions,
    ...toastProps,
  });
};

// Info variation of notificationAlert with typed parameters
notificationAlert.info = (notificationProps: MsgProps, toastProps: ToastOptions = {}): Id => {
  const { title } = notificationProps;

  // Define the click handler
  const handleClick = () => {
    console.log(`Info toast with title clicked: ${title}`);
  };

  return toast.info(<Msg {...notificationProps} onClick={handleClick} />, {
    ...defaultToastOptions,
    ...toastProps,
  });
};

// Warn variation of notificationAlert with typed parameters
notificationAlert.warn = (notificationProps: MsgProps, toastProps: ToastOptions = {}): Id => {
  const { title } = notificationProps;

  // Define the click handler
  const handleClick = () => {
    console.log(`Warn toast with title clicked: ${title}`);
  };

  return toast.warn(<Msg {...notificationProps} onClick={handleClick} />, {
    ...defaultToastOptions,
    ...toastProps,
  });
};
