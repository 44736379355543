import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect, useCallback } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import useDebounce from 'src/hooks/use-debounce';

import CustomDateRangePicker from 'src/components/date-range-picker';

interface FilterProps {
  onDateChange: (startDate: string, endDate: string) => void;
  onSearch: (query: string) => void;
  onTransactionTypeChange: (type: string) => void;
  searchResultCount: number;
  disableTransactionType?: boolean;
}

const FilterComponent: React.FC<FilterProps> = ({
  onDateChange,
  onSearch,
  onTransactionTypeChange,
  searchResultCount,
  disableTransactionType,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTransactionType, setSelectedTransactionType] = useState<string>('');
  const [dateRangeState, setDateRangeState] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().subtract(3, 'day').startOf('day'),
    dayjs().endOf('day'),
  ]);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    onSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery, onSearch]);

  useEffect(() => {
    const [start, end] = dateRangeState;
    if (start && end) {
      const startDate = start.format('DD-MM-YYYY');
      const endDate = end.format('DD-MM-YYYY');
      if (
        dayjs(startDate, 'DD-MM-YYYY', true).isValid() &&
        dayjs(endDate, 'DD-MM-YYYY', true).isValid()
      ) {
        onDateChange(startDate, endDate);
      } else {
        console.error('Invalid initial date format. Please use DD-MM-YYYY.');
      }
    }
  }, [dateRangeState, onDateChange]);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleTransactionTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const type = event.target.value as string;
      onTransactionTypeChange(type);
    },
    [onTransactionTypeChange]
  );

  const handleDateChangeWrapper = useCallback(
    (newDateRange: [Dayjs | null, Dayjs | null]) => {
      if (newDateRange[0] && newDateRange[1]) {
        const startDate = newDateRange[0].format('DD-MM-YYYY');
        const endDate = newDateRange[1].format('DD-MM-YYYY');
        if (
          dayjs(startDate, 'DD-MM-YYYY', true).isValid() &&
          dayjs(endDate, 'DD-MM-YYYY', true).isValid()
        ) {
          onDateChange(startDate, endDate);
        } else {
          console.error('Invalid date format. Please use DD-MM-YYYY.');
        }
      }
      setDateRangeState(newDateRange);
    },
    [onDateChange]
  );

  return (
    <Grid container direction="row" spacing={2} p={2}>
      <Grid item container spacing={2}  md={12}>
        <Grid item xs={6} sm={6} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
            label="Search customer mobile or customer name"
            variant="outlined"
            onChange={handleSearchChange}
          />
        </Grid>
      <Grid item md={6}>
          <CustomDateRangePicker
            onDateChange={handleDateChangeWrapper}
            initialDateRange={dateRangeState}
          />
        </Grid>
      <Grid item xs={4} sm={4} md={2}>
          {!disableTransactionType ? (
            <FormControl fullWidth variant="outlined">
              <InputLabel id="transaction-type-label">Transaction Type</InputLabel>
              <Select
                labelId="transaction-type-label"
                onChange={handleTransactionTypeChange}
                label="Transaction Type"
                defaultValue=" "
              >
                <MenuItem value=" ">All</MenuItem>
                <MenuItem value="PHYSICAL_GOLD">Physical Gold</MenuItem>
                <MenuItem value="RELEASED_GOLD">Takeover Gold</MenuItem>
              </Select>
            </FormControl>
          ) : null}
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Typography variant="body1">{searchResultCount} results found</Typography>
      </Grid>
    </Grid>
  );
};

export default FilterComponent;
