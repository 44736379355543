interface Notification {
  title: string;
  content: string;
  web_url: string;
  read: boolean;
  allNotificationsCount: number;
  unreadNotifcationsCount: number;
  allNotificationsRecords: number;
  unreadNotificationsRecords: number;
  allNotifications: any;
  id: string;
}

// Transforms an array of notifications into an object keyed by ID and an array of IDs
export function transformNotifications(records: Notification[]): {
  recordIds: string[];
  recordObj: Record<string, Notification>;
} {
  const recordIds: string[] = [];
  const recordObj: Record<string, Notification> = {};

  records.forEach((item: Notification) => {
    recordObj[item.id] = item;
    recordIds.push(item.id);
  });

  return { recordIds, recordObj };
}
