import API from '..';
import { regions } from '../entpoints';

interface Options {
  label: string;
  value: string;
}

export interface RegionsParams {
  id?: string;
  region_name: string;
  manager_id: Options;
  branch_ids: Options[];
  force_assign: boolean;
}

/* eslint-disable no-useless-catch */
export const getEmployees = async (searchText: string) => {
  try {
    const resp = await API.get(`${regions.getEmployees}?search_text=${searchText}`);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getBranches = async (searchText: string) => {
  try {
    const resp = await API.get(`${regions.getBranches}?search_text=${searchText}`);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const createRegion = async (body: RegionsParams) => {
  try {
    const options = {
      name: body.region_name,
      manager_id: body.manager_id.value,
      branch_ids: body.branch_ids.map(({ value }: any) => value),
      force_assign: body.force_assign,
    };
    const resp = await API.post(`${regions.createRegion}`, options);
    return resp;
  } catch (error) {
    throw error.response.data.message;
  }
};

// Get region detail by id
export const fetchRegionById = async (regionId?: string) => {
  try {
    const resp = await API.get(`${regions.getRegionsById}/${regionId}`);
    return resp;
  } catch (error) {
    throw error;
  }
};

// Get all regions
export const getRegions = async (page_size?: number | null, page_number?: number | null) => {
  try {
    const resp = await API.get(
      `${regions.getAllRegions}?page_size=${page_size}&page_number=${page_number}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

// update region
export const updateRegion = async (body: RegionsParams) => {
  try {
    const options = {
      name: body.region_name,
      manager_id: body.manager_id.value,
      branch_ids: body.branch_ids.map(({ value }: any) => value),
    };
    const resp = await API.patch(`${regions.updateRegion}/${body.id}`, options);
    return resp;
  } catch (error) {
    throw error.response.data.message;
  }
};
