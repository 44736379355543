import Cookies from 'universal-cookie';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class API {
  axios: AxiosInstance;

  cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
    this.axios = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: { 'Content-Type': 'application/json' },
    });

    // redirects the user to the auth app when status code is 401
    this.axios.interceptors.response.use(
      // eslint-disable-next-line arrow-body-style
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.cookies.remove('auth-token');
        } else if (error.response.status === 403) {
          window.location.replace('/403');
        }
        return Promise.reject(error);
      }
    );
  }

  setAccessToken = (token: string) => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  getAccessToken = () => {
    const authorizationHeader = this.axios.defaults.headers.common.Authorization;
    if (typeof authorizationHeader === 'string') {
      return authorizationHeader.split(' ')[1];
    }
    return undefined;
  };

  /* eslint-disable no-useless-catch */
  post = async (path: string, data: any, config?: AxiosRequestConfig) => {
    try {
      const resp = await this.axios.post(path, data, config);
      return resp.data;
    } catch (error) {
      throw error;
    }
  };

  get = async (path: string) => {
    try {
      const resp = await this.axios.get(path);
      return resp.data;
    } catch (error) {
      throw error;
    }
  };

  patch = async (path: string, data: any, options?: any) => {
    try {
      const resp = await this.axios.patch(path, data, options);
      return resp.data;
    } catch (error) {
      throw error;
    }
  };

  delete = async (path: string, data: any) => {
    try {
      const resp = await this.axios.delete(path, data);
      return resp.data;
    } catch (error) {
      throw error;
    }
  };
}

export default new API();
