import React from 'react';

import { Box } from '@mui/material';

interface OverlayProps {
  showOverlay?: boolean;
}

const Overlay: React.FC<OverlayProps> = ({ showOverlay = true }) => {
  if (!showOverlay) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        opacity: 0.5,
        position: 'absolute',
        zIndex: 100,
        top: 0,
        left: 0,
        bottom: 0, // Ensure the overlay covers the full height of the parent
        right: 0, // Ensure the overlay covers the full width of the parent
      }}
    />
  );
};

export default Overlay;
