import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  viewBoxHeight?: string;
  viewBoxWidth?: string;
  svgHeight?: string;
  svgWidth?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    { disabledLink = false, sx, viewBoxHeight, viewBoxWidth, svgHeight, svgWidth, ...other },
    ref
  ) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${viewBoxHeight} ${viewBoxWidth}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="20" fill="#FFFFFF" />
          <path
            d="M8 17.2308H9.53846L16.9231 32L20.3077 25.6213M18.7692 23.0769L15.6923 16.6154L20.3077 8L21.5385 10.3795M18.7692 23.0769L20.3077 20.2051M18.7692 23.0769L16.6154 27.3846L11.3846 16.9231M20.3077 12.9231L22.4615 16.6154L20.3077 20.2051M20.3077 12.9231L18.1538 16.6154L20.3077 20.2051M20.3077 12.9231L21.5385 10.3795M21.5385 10.3795L24.9231 16.9231L21.6578 23.0769M20.3077 25.6213L23.3846 32L31.3846 17.2308H32.6154M20.3077 25.6213L21.6578 23.0769M21.6578 23.0769L23.3846 27.0769L28.9231 16.9231"
            stroke="#002EA7"
          />
          <path
            d="M51.2607 15.9043L48.9434 7.11328L48 7.01074V5.95117H51.6777V7.01074L50.6182 7.16797L51.8418 12.2812L52.0947 13.3271L52.1357 13.334L52.4502 12.2812L54.4326 5.95117H55.7793L57.8164 12.3838L58.1172 13.4023H58.1582L58.4248 12.3838L59.6689 7.16797L58.5479 7.01074V5.95117H62.1572V7.01074L61.2754 7.11328L58.9854 15.9043H57.3994L55.3965 9.63574L55.1436 8.7334H55.1025L54.8359 9.64941L52.8193 15.9043H51.2607Z"
            fill="#FFFFFF"
          />
          <path
            d="M62.957 15.9043V14.8516L64.0439 14.6465V7.21582L62.957 7.01074V5.95117H66.8535V7.01074L65.7666 7.21582V10.3193H70.1211V7.21582L69.0342 7.01074V5.95117H72.9238V7.01074L71.8369 7.21582V14.6465L72.9238 14.8516V15.9043H69.0342V14.8516L70.1211 14.6465V11.6592H65.7666V14.6465L66.8535 14.8516V15.9043H62.957Z"
            fill="#FFFFFF"
          />
          <path
            d="M73.8262 15.9043V14.8516L74.9131 14.6465V7.21582L73.8262 7.01074V5.95117H77.7158V7.01074L76.6357 7.21582V14.6465L77.7158 14.8516V15.9043H73.8262Z"
            fill="#FFFFFF"
          />
          <path
            d="M80.9902 15.9043V14.8516L82.0771 14.6465V7.27051H80.0742L79.9238 8.54199H78.6318V5.95117H87.252V8.54199H85.9531L85.8027 7.27051H83.7998V14.6465L84.8867 14.8516V15.9043H80.9902Z"
            fill="#FFFFFF"
          />
          <path
            d="M88.168 15.9043V14.8516L89.2549 14.6465V7.21582L88.168 7.01074V5.95117H95.749V8.51465H94.4092L94.293 7.27734H90.9775V10.0938H94.4707V11.4268H90.9775V14.5918H94.4775L94.5938 13.3271H95.9199V15.9043H88.168Z"
            fill="#FFFFFF"
          />
          <path
            d="M52.6826 34.0479C51.7256 34.0479 50.903 33.8405 50.2148 33.4258C49.5267 33.0065 48.998 32.4277 48.6289 31.6895C48.2598 30.9466 48.0752 30.0876 48.0752 29.1123V28.8457C48.0752 27.8477 48.2575 26.9727 48.6221 26.2207C48.9912 25.4642 49.5062 24.874 50.167 24.4502C50.8278 24.0218 51.598 23.8076 52.4775 23.8076C53.2158 23.8076 53.8812 23.9033 54.4736 24.0947C55.0661 24.2816 55.5765 24.5505 56.0049 24.9014V27.0273H54.6855L54.4668 25.6465C54.2663 25.4961 54.0111 25.3776 53.7012 25.291C53.3913 25.2044 53.0312 25.1611 52.6211 25.1611C52.0605 25.1611 51.5661 25.3161 51.1377 25.626C50.7139 25.9313 50.3835 26.3597 50.1465 26.9111C49.9141 27.458 49.7979 28.0983 49.7979 28.832V29.1123C49.7979 29.8369 49.9095 30.4681 50.1328 31.0059C50.3607 31.5436 50.6911 31.9606 51.124 32.2568C51.5615 32.5531 52.0924 32.7012 52.7168 32.7012C53.1133 32.7012 53.4619 32.6647 53.7627 32.5918C54.068 32.5189 54.3278 32.4277 54.542 32.3184V30.3564L52.915 30.2607V29.0371H56.2646V32.9883C55.8864 33.2845 55.4033 33.5352 54.8154 33.7402C54.2321 33.9453 53.5212 34.0479 52.6826 34.0479Z"
            fill="#FFFFFF"
          />
          <path
            d="M61.8564 34.0479C61.1956 34.0479 60.5986 33.9248 60.0654 33.6787C59.5322 33.4281 59.0742 33.0771 58.6914 32.626C58.3132 32.1748 58.0215 31.6416 57.8164 31.0264C57.6113 30.4111 57.5088 29.7367 57.5088 29.0029V28.8525C57.5088 28.1234 57.6113 27.4512 57.8164 26.8359C58.0215 26.2207 58.3132 25.6875 58.6914 25.2363C59.0742 24.7806 59.5322 24.4297 60.0654 24.1836C60.5986 23.9329 61.1934 23.8076 61.8496 23.8076C62.5286 23.8076 63.137 23.9329 63.6748 24.1836C64.2126 24.4297 64.6706 24.7806 65.0488 25.2363C65.4271 25.6875 65.7165 26.2207 65.917 26.8359C66.1175 27.4512 66.2178 28.1234 66.2178 28.8525V29.0029C66.2178 29.7367 66.1175 30.4111 65.917 31.0264C65.7165 31.6416 65.4271 32.1748 65.0488 32.626C64.6706 33.0771 64.2126 33.4281 63.6748 33.6787C63.137 33.9248 62.5309 34.0479 61.8564 34.0479ZM61.8564 32.6807C62.458 32.6807 62.9525 32.528 63.3398 32.2227C63.7318 31.9128 64.0212 31.4821 64.208 30.9307C64.3994 30.3792 64.4951 29.7367 64.4951 29.0029V28.8389C64.4951 28.1143 64.3994 27.4785 64.208 26.9316C64.0166 26.3848 63.7249 25.9564 63.333 25.6465C62.9456 25.3366 62.4512 25.1816 61.8496 25.1816C61.2754 25.1816 60.7946 25.3366 60.4072 25.6465C60.0199 25.9564 59.7282 26.3848 59.5322 26.9316C59.3363 27.4785 59.2383 28.1143 59.2383 28.8389V29.0029C59.2383 29.7321 59.3363 30.3747 59.5322 30.9307C59.7282 31.4821 60.0199 31.9128 60.4072 32.2227C60.7992 32.528 61.2822 32.6807 61.8564 32.6807Z"
            fill="#FFFFFF"
          />
          <path
            d="M67.1133 33.9043V32.8516L68.2002 32.6465V25.2158L67.1133 25.0107V23.9512H71.0918V25.0107L69.9229 25.2158V32.585H73.0332L73.1494 31.0811H74.4688V33.9043H67.1133Z"
            fill="#FFFFFF"
          />
          <path
            d="M75.3438 33.9043V32.8516L76.4375 32.6396V25.2295L75.3438 25.0107V23.9512H79.5137C80.4023 23.9512 81.1794 24.1608 81.8447 24.5801C82.5146 24.9993 83.0365 25.5758 83.4102 26.3096C83.7839 27.0433 83.9707 27.8841 83.9707 28.832V29.0303C83.9707 29.9645 83.7884 30.8008 83.4238 31.5391C83.0592 32.2728 82.5443 32.8516 81.8789 33.2754C81.2181 33.6947 80.4456 33.9043 79.5615 33.9043H75.3438ZM78.1602 32.5781H79.4727C80.056 32.5781 80.5527 32.4232 80.9629 32.1133C81.3776 31.7988 81.6943 31.375 81.9131 30.8418C82.1318 30.304 82.2412 29.7002 82.2412 29.0303V28.8252C82.2412 28.137 82.1318 27.5286 81.9131 27C81.6943 26.4668 81.3776 26.0475 80.9629 25.7422C80.5527 25.4368 80.056 25.2842 79.4727 25.2842H78.1602V32.5781Z"
            fill="#FFFFFF"
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.defaultProps = {
  viewBoxHeight: '96',
  viewBoxWidth: '40',
  svgHeight: '40',
  svgWidth: '96',
};

export default Logo;
