import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TeleBasicFormValues } from 'src/interfaces';
import { mergeLead, assignLead ,
  createLead,
  updateLead,
  getLeadById,
  assignNewLead,
  getTeleSalesCounts,
  getTelesalesFollowups,
  getTelesalesTransactions,
} from 'src/apis/telesales/index';

type TransactionCount = {
  [key: string]: number;
};
interface LeadState {
  loading: boolean;
  currentPage: number;
  currentPageSize: number;
  totalRecords: number;
  records: any[];
  currentLead: any;
  error: string | undefined;
  transactionCount: TransactionCount;
}

const initialState: LeadState = {
  loading: false,
  error: undefined,
  currentPage: 1,
  currentPageSize: 10,
  totalRecords: 0,
  records: [],
  currentLead: null,
  transactionCount: {},
};

export const getTelesalesTransaction = createAsyncThunk(
  'leads/telesales',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: any;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const transactions = await getTelesalesTransactions(
      status ?? '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return transactions;
  }
);
export const getTelesalesFollowupTransaction = createAsyncThunk(
  'leads/followups',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: any;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const transactions = await getTelesalesFollowups(
      status ?? '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return transactions;
  }
);
// Async actions for leads
export const createLeadRequest = createAsyncThunk(
  'leads/create_lead',
  async (body: TeleBasicFormValues) => {
    const response = await createLead(body);
    return response;
  }
);

export const updateLeadRequest = createAsyncThunk(
  'leads/update_lead',
  async ({ lead_id, body }: { lead_id: string; body: TeleBasicFormValues }) => {
    const response = await updateLead(lead_id, body);
    return response;
  }
);

export const getLeadRequest = createAsyncThunk('leads/get_lead', async (lead_id: string) => {
  const response = await getLeadById(lead_id);
  return response;
});

export const mergeLeadRequest = createAsyncThunk(
  'leads/merge_lead',
  async ({ lead_id, payload }: { lead_id: string; payload: any }) => {
    const response = await mergeLead(lead_id, payload);
    return response;
  }
);

export const assignLeadRequest = createAsyncThunk(
  'leads/assign_lead',
  async (searchText?: string) => {
    const response = await assignLead(searchText);
    return response;
  }
);

export const fetchCountsLead = createAsyncThunk(
  'leads/fetchCounts',
  async () => {
    const counts = await getTeleSalesCounts();
    return counts;
  }
)

export const assignNewLeadRequest = createAsyncThunk(
  'leads/assign_new_lead',
  async ({ call_id, payload }: { call_id: string; payload: { assignee_id: string } }) => {
    const response = await assignNewLead(call_id, payload);
    return response;
  }
);

// Create the lead slice
const leadSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setCurrentLead(state, action) {
      state.currentLead = action.payload;
    },
    updateCurrentLead(state, action) {
      state.currentLead = { ...state.currentLead, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTelesalesTransaction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getTelesalesTransaction.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(getTelesalesTransaction.fulfilled, (state, action) => {
      state.records = action.payload.records || [];
      state.currentPageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });
    builder.addCase(getTelesalesFollowupTransaction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getTelesalesFollowupTransaction.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(getTelesalesFollowupTransaction.fulfilled, (state, action) => {
      state.records = action.payload.records || [];
      state.currentPageSize = action.payload.total_pages;
      state.totalRecords = action.payload.total_records;
      state.currentPage = action.payload.current_page;
      state.loading = false;
    });
    builder.addCase(createLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(createLeadRequest.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(updateLeadRequest.fulfilled, (state, action) => {
      state.currentLead = action.payload;
      state.loading = false;
    });

    builder.addCase(getLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(getLeadRequest.fulfilled, (state, action) => {
      state.currentLead = action.payload;
      state.loading = false;
    });

    builder.addCase(mergeLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(mergeLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(mergeLeadRequest.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(assignLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(assignLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(assignLeadRequest.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchCountsLead.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchCountsLead.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(fetchCountsLead.fulfilled, (state, action) => {
      state.transactionCount = action.payload;
      state.loading = false;
    });

    builder.addCase(assignNewLeadRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(assignNewLeadRequest.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });

    builder.addCase(assignNewLeadRequest.fulfilled, (state, action) => {
      const { assignee_id } = action.meta.arg.payload;
      const { first_name, last_name } = action.payload.updatedCall.employee;
      const recordIndex = state.records.findIndex(
        (record) => record.call_id === action.meta.arg.call_id
      );

      if (recordIndex !== -1) {
        state.records[recordIndex].employee_id = assignee_id;
        state.records[recordIndex].employee.first_name = first_name;
        state.records[recordIndex].employee.last_name = last_name;
      }

      state.loading = false;
    });
  },
});

export const { setCurrentLead, updateCurrentLead } = leadSlice.actions;
export default leadSlice.reducer;
