import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import formatINR from 'src/utils/format-inr';

// Components
import { CustomTooltip, PasswordValidationPopup } from '../password-popup';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  showPassword?: boolean;
  handleClickShowPassword?: any;
  showPasswordToggle?: boolean;
  showPasswordValidator?: boolean;
  showRupeeAdornment?: boolean;
  formatRupee?: boolean; // New prop to handle rupee formatting
};

export default function RHFTextField({
  name,
  label,
  helperText,
  type,
  showPassword,
  handleClickShowPassword,
  showPasswordToggle,
  showPasswordValidator,
  showRupeeAdornment,
  formatRupee = false, // Set default to false
  ...other
}: Props) {
  const { control } = useFormContext();
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  // Function to determine the input type
  const determineInputType = () => {
    if (showPassword) {
      return 'password';
    }
    if (formatRupee) {
      return 'text';
    }
    return type;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CustomTooltip
          showPopup={showPasswordValidator || false}
          title={<PasswordValidationPopup password={field.value} />}
          placement="bottom"
          open={showPasswordValidator && focused}
          arrow
        >
          <TextField
            {...field}
            fullWidth
            onFocus={handleFocus}
            onBlur={handleBlur}
            onWheel={(e: any) => e.target.blur()}
            type={determineInputType()} // Use the function to determine input type
            value={formatRupee && !focused && field.value ? formatINR(field.value) : field.value}
            onChange={(event) => {
              const rawValue = event.target.value;
              // Handle rupee formatting
              if (formatRupee) {
                // Only allow numeric characters and dots
                const numericValue = rawValue.replace(/[^0-9.]/g, '');
                field.onChange(numericValue);
              } else {
                // Handle normal text or number input
                field.onChange(rawValue);  // Simply update value as entered
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            label={label}
            InputLabelProps={{ shrink: !!field.value }}
            {...other}
            InputProps={{
              startAdornment: showRupeeAdornment ? (
                <InputAdornment position="start">₹</InputAdornment>
              ) : null,
              endAdornment: (
                <InputAdornment position="end">
                  {showPasswordToggle ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff sx={{ height: 20 }} /> : <Visibility sx={{ height: 20 }} />}
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
              ...other.InputProps,
            }}
          />
        </CustomTooltip>
      )}
    />
  );
}

