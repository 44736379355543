import Cookies from 'universal-cookie';

class Cookie {
  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  // Method to set cookies
  setCookies(cookieName: string, cookieValue: string, options: any) {
    this.cookies.set(cookieName, cookieValue, options);
  }

  // Method to get cookies
  getCookies(cookieName: string) {
    return this.cookies.get(cookieName);
  }

  // Method removes cookie
  removeCookie(cookieName: string) {
    this.cookies.remove(cookieName);
    this.cookies.remove(cookieName, { path: '/' });
    this.cookies.remove(cookieName, { path: '/dashboard' });
  }
}

export default new Cookie();
