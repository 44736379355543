// @ts-nocheck
/* eslint-disable */

import {
  KYC,
  Estimation,
  Transaction,
  Quotation,
  ReleaseFormValues,
  TeleBasicFormValues,
  Kyc,
} from 'src/interfaces';

export const branchTransactionStatusHandlers = (transaction: Transaction) => {
  if (transaction.status === 'WALKOUT') {
    return {
      title: 'Customer Walk-out',
      message: transaction.walk_out_reason,
      type: 'error',
      status: transaction?.status,
    };
  }
  return {
    title: null,
    status: '',
    message: null,
    type: undefined,
  };
};

export const kycStatusHandlers = (transaction: Transaction, kyc: KYC) => {
  // First, check transaction status
  if (transaction.status === 'KYC_PENDING') {
    return {
      title: 'KYC Verification',
      message:
        'This KYC application is in process. Please check back once it has been approved by the external team.',
      type: 'warning',
      status: transaction?.status,
    };
  } else if (transaction.status === 'WALKOUT') {
    return {
      title: 'Customer Walk-out',
      message: transaction.walk_out_reason,
      type: 'error',
      status: transaction?.status,
    };
  }

  // Check KYC status with if-else statements
  let remark = null;

  if (kyc?.status === 'CHECKER_REQ_DOC') {
    remark = kyc.checker_remark
      ? kyc.checker_remark
      : 'Please add the requested documents with clear images for further proceedings.';
    return {
      title: 'Additional Documents Requested',
      message: `Remarks: ${remark}`,
      type: 'warning',
    };
  } else if (kyc?.status === 'MAKER_REQ_DOC') {
    remark = kyc.maker_remark
      ? kyc.maker_remark
      : 'Please add the requested documents with clear images for further proceedings.';
    return {
      title: 'Maker: Additional Documents Requested',
      message: `Remarks: ${remark}`,
      type: 'warning',
    };
  } else if (kyc?.status === 'MAKER_REJECTED') {
    remark = kyc.maker_remark
      ? kyc.maker_remark
      : 'The KYC maker has rejected the verification. Please mark the customer as having walked out.';
    return {
      title: 'KYC Rejected by Maker',
      message: `Remarks: ${remark}`,
      type: 'error',
    };
  } else if (kyc?.status === 'CHECKER_REJECTED') {
    remark = kyc.checker_remark
      ? kyc.checker_remark
      : 'The KYC checker has rejected the verification. Please mark the customer as having walked out.';
    return {
      title: 'KYC Rejected by Checker',
      message: `Remarks: ${remark}`,
      type: 'error',
    };
  } else if (kyc?.status === 'CHECKER_APPROVED') {
    remark = kyc.checker_remark
      ? kyc.checker_remark
      : 'The KYC checker has approved the verification. Please proceed with the next steps.';
    return {
      title: 'Checker Approved',
      message: `Remarks: ${remark}`,
      type: 'info',
    };
  } else if (kyc?.status === 'MAKER_APPROVED') {
    remark = kyc.maker_remark
      ? kyc.maker_remark
      : 'The KYC maker has approved the verification. Please proceed with the next steps.';
    return {
      title: 'Maker Approved',
      message: `Remarks: ${remark}`,
      type: 'info',
    };
  } else {
    // Default case when no status matches
    return {
      title: null,
      status: '',
      type: undefined,
      message: null,
    };
  }
};

export const kycStatusHandlersMaker = (kyc: Kyc, role?: string) => {
  if (kyc?.status === 'CHECKER_PENDING' && role === 'MAKER') {
    return {
      title: 'Checker Pending',
      message: `This KYC application is in process. Please check back once it has been approved by the external team.`,
      type: 'warning',
      status: kyc?.status,
    };
  }

  if (kyc?.status === 'MAKER_REJECTED') {
    return {
      title: 'Maker Rejected',
      message: kyc.maker_remark
        ? `Remarks: ${kyc.maker_remark}`
        : `The KYC was rejected by the maker. Please check the KYC status again for updates.`,
      type: 'error',
      status: kyc?.status,
    };
  }

  if (kyc?.maker_status === 'APPROVED' && role === 'KYC_MAKER') {
    return {
      title: 'Approved',
      message: kyc.maker_remark
        ? `Remarks: ${kyc.maker_remark}`
        : `The KYC is approved by the maker. Please check back once it has been approved by the external team.`,
      type: 'info',
      status: kyc?.status,
    };
  } if (kyc?.status === 'MAKER_REQ_DOC') {
    return {
      title: 'Documents Requested',
      message: kyc.maker_remark
        ? `Remarks: ${kyc.maker_remark}`: `The maker has requested additional documents. Please provide the necessary documents for verification.`,
      type: 'warning',
      status: kyc?.status,
    };
  }

  if (kyc?.status === 'CHECKER_REQ_DOC') {
    return {
      title: 'Documents Requested',
      message: kyc.checker_remark
      ? `Remarks: ${kyc.checker_remark}`: `The checker has requested additional documents. Please provide the necessary documents for verification.`,
      type: 'warning',
      status: kyc?.status,
    };
  }

  if (kyc?.status === 'CHECKER_REJECTED') {
    return {
      title: 'Checker Rejected',
      message: kyc.checker_remark
        ? `Remarks: ${kyc.checker_remark}`
        : 'The KYC checker has rejected the verification. Please mark the customer as having walked out.',
      type: 'error',
      status: kyc?.status,
    };
  }

  if (kyc?.status === 'CHECKER_APPROVED') {
    return {
      title: 'Approved',
      message: kyc.checker_remark
        ? `Remarks: ${kyc.checker_remark}`
        : `The KYC is approved by the checker. Please check back once it has been approved by the external team.`,
      type: 'info',
      status: kyc?.status,
    };
  }

  return {
    title: null,
    status: '',
    type: undefined,
    message: null,
  };
};


export const branchEstimationStatusHandlers = (
  estimations?: Estimation,
  transaction?: Transaction
) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  let negotiationStatus = [
    'SALES_NEGOTIATION_PENDING',
    'SALES_NEGOTIATION_REJECTED',
    'SALES_NEGOTIATION_COMPLETED',
  ];

  let serviceChargeStatus = ['SALES_APPROVAL_PENDING', 'SALES_REJECTED', 'SALES_APPROVED'];

  let salesHeadStatus = [
    'SALES_HEAD_APPROVAL_PENDING',
    'SALES_HEAD_REJECTED',
    'SALES_HEAD_PENDING',
  ];

  if (transaction?.status === 'WALKOUT') {
    title = 'Customer Walk-out';
    message = transaction.walk_out_reason;
    type = 'error';
    status = transaction?.status;
  }

  if (estimations.assayer_status && estimations.status == 'VALUATION_PENDING') {
    switch (estimations.assayer_status) {
      case 'PENDING':
        title = 'Gold Valuation Pending';
        remark =
          'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.';
        status = estimations.assayer_status;
        type = 'warning';
        break;
    }
  }
  if (estimations.negotiation_approval && negotiationStatus.includes(estimations.status)) {
    switch (estimations.negotiation_approval) {
      case 'PENDING':
        title = 'Sales Negotiation Pending';
        remark =
          'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.';
        status = estimations.negotiation_approval;
        type = 'warning';
        break;

      case 'APPROVED':
        title = 'Sales Negotiation Approved';
        remark =
          (estimations.negotiation_remark ? `Remark: ${estimations.negotiation_remark}` :
          'Negotiation has been finalized. Please carefully review the modifications implemented by the sales team.');
        status = estimations.negotiation_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Sales Negotiation Rejected';
        remark =
          (estimations.negotiation_remark ? `Remark: ${estimations.negotiation_remark}` :
          'The Sales has rejected the approval. Please mark the customer as having walked out.');
        status = estimations.negotiation_approval;
        type = 'error';
        break;
    }
  }

  // check sales service charge approval status
  if (estimations.service_charge_approval && serviceChargeStatus.includes(estimations.status)) {
    switch (estimations.service_charge_approval) {
      case 'PENDING':
        title = 'Service Charge Approval Pending';
        remark =
          'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.';
        status = estimations.service_charge_approval;
        type = 'warning';
        break;

      case 'APPROVED':
        title = 'Service Charge Approved';
        remark =
          (estimations.service_charge_remark ? `Remark: ${estimations.service_charge_remark}` :
          'Service Charge has been finalized. Please carefully review the modifications implemented by the sales team.');
        status = estimations.service_charge_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Service Charge Approval Rejected';
        remark =
          (estimations.service_charge_remark ? `Remark: ${estimations.service_charge_remark}` :
          'The Sales has rejected the service charge approval. Please mark the customer as having walked out.');
        status = estimations.service_charge_approval;
        type = 'error';
        break;
    }
  }

  // Check the sales head gold rate approval status
  if (estimations?.gold_rate_approval && salesHeadStatus.includes(estimations.status)) {
    switch (estimations.gold_rate_approval) {
      case 'PENDING':
        title = 'Gold Rate Approval Pending';
        remark =
          'There have been some modifications made to the gold rates by the sales team, which now require approval from the Sales Head. Please check back once it has been approved by the external team.';
        status = estimations.gold_rate_approval;
        type = 'warning';
        break;

      case 'APPROVED':
        title = 'Gold Rate Changes Approved';
        remark =
          (estimations.gold_rate_remark ? `Remark: ${estimations.gold_rate_remark}` :
          'Gold rate changes have been finalized. Please carefully review the modifications implemented by the sales team.');
        status = estimations.gold_rate_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Gold Rate Changes Rejected';
        remark =
          (estimations.gold_rate_remark ? `Remark: ${estimations.gold_rate_remark}` :
          'The Sales has rejected the gold rate changes. Please mark the customer as having walked out.');
        status = estimations.gold_rate_approval;
        type = 'error';
        break;
    }
  }

  return {
    title,
    message: remark,
    status,
    type,
  };
};


export const salesStatusHandlers = (estimations: Estimation) => {
  switch (estimations.status) {
    case 'SALES_HEAD_APPROVAL_PENDING':
      return {
        title: 'Sales Head Approval Pending',
        message:
          'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.',
        type: 'warning',
        status: estimations.status,
      };

    case 'SALES_REJECTED':
      return {
        title: 'Sales Rejected',
        status: estimations.status,
        message: `${
          estimations.sales_remark
            ? `Remark : ${estimations.sales_remark}`
            : 'Your Changes were Successfully Submitted to the branch'
        }`,
        type: 'error',
      };

    case 'SALES_NEGOTIATION_COMPLETED':
      return {
        title: 'Sales Negotiation Done!',
        status: estimations.status,
        message: estimations.sales_remark
          ? `Remark : ${estimations.sales_remark}`
          : 'Your Changes were Successfully Submitted to the branch',
        type: 'info',
      };

    case 'SALES_APPROVED':
      return {
        title: 'Sales Approved!',
        status: estimations.status,
        message: `${
          estimations.sales_remark
            ? `Remark : ${estimations.sales_remark}`
            : 'Your Changes were Successfully Submitted to the branch.'
        }`,
        type: 'info',
      };
    default:
      return {
        title: null,
        status: '',
        message: null,
        type: undefined,
      };
  }
};

export const salesHeadStatusHandlers = (estimations: any) => {
  if (estimations.estimation_sales_head_approval) {
    switch (estimations.estimation_sales_head_approval) {
      case 'APPROVED':
        return {
          title: 'Approved',
          status: estimations.estimation_sales_head_approval,
          message: estimations.estimation_sales_head_remark
            ? `Remark : ${estimations.estimation_sales_head_remark}`
            : 'Your Changes were Successfully Submitted to the branch',
          type: 'info',
        };

      case 'REJECTED':
        return {
          title: 'Rejected',
          status: estimations.estimation_sales_head_approval,
          message: `${
            estimations.estimation_sales_head_remark
              ? `Remark : ${estimations.estimation_sales_head_remark}`
              : 'Your Changes were Successfully Submitted to the branch'
          }`,
          type: 'error',
        };
    }
  } else if (estimations.estimation_sales_approval) {
    switch (estimations.estimation_sales_approval) {
      case 'APPROVED':
        return {
          title: 'Approved',
          status: estimations.estimation_sales_approval,
          message: estimations.estimation_sales_remark
            ? `Remark : ${estimations.estimation_sales_remark}`
            : 'Your Changes were Successfully Submitted to the branch',
          type: 'info',
        };

      case 'REJECTED':
        return {
          title: 'Rejected',
          status: estimations.estimation_sales_approval,
          message: `${
            estimations.estimation_sales_remark
              ? `Remark : ${estimations.estimation_sales_remark}`
              : 'Your Changes were Successfully Submitted to the branch'
          }`,
          type: 'error',
        };
    }
  }

  return {
    title: null,
    status: null,
    message: null,
    type: null,
  };
};

// When sales approves/rejects/pending Release final amount - For Sales
export const NegotiationApprovalSalesMessage = (estimations: ReleaseFormValues) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  // sales message
  if (estimations.negotiation_approval) {
    switch (estimations.negotiation_approval) {
      case 'APPROVED':
        title = 'Approved';
        remark = `Remark: ${estimations.negotiation_remark ? estimations.negotiation_remark : 'You approved the request.'}`;
        status = estimations.negotiation_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Rejected';
        remark = `Remark: ${estimations.negotiation_remark ? estimations.negotiation_remark : 'You rejected this approval.'}`;
        status = estimations.negotiation_approval;
        type = 'error';
        break;
    }
  } else if (estimations.gold_rate_approval) {
    switch (estimations.gold_rate_approval) {
      case 'PENDING':
        title = 'Gold Rate Approval Pending';
        remark =
          'Your changes have been successfully forwarded to the Sales Head for gold rate approval.';
        status = estimations.gold_rate_approval;
        type = 'info';
        break;
    }
  }

  return {
    title,
    message: remark,
    status,
    type,
  };
};


// When sales approves/rejects/pending Release final amount - For Sales
export const serviceChargeApprovalSalesMessage = (estimations: ReleaseFormValues) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  // sales message
  if (estimations.service_charge_approval) {
    switch (estimations.service_charge_approval) {
      case 'APPROVED':
        title = 'Approved';
        remark = estimations.service_charge_remark
          ? `Remark: ${estimations.service_charge_remark}`
          : 'You approved the request.';
        status = estimations.service_charge_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Rejected';
        remark = estimations.service_charge_remark
          ? `Remark: ${estimations.service_charge_remark}`
          : 'You rejected this approval.';
        status = estimations.service_charge_approval;
        type = 'error';
        break;
    }
  }

  return {
    title,
    message: remark,
    status,
    type,
  };
};


// When sales head approves/rejects/pending gold rates
export const goldRateApprovalSalesHeadMessage = (estimations: ReleaseFormValues) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  // sales message
  if (estimations.gold_rate_approval) {
    switch (estimations.gold_rate_approval) {
      case 'APPROVED':
        title = 'Approved';
        remark = estimations.gold_rate_remark
          ? `Remark: ${estimations.gold_rate_remark}`
          : 'You approved the request.';
        status = estimations.gold_rate_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Rejected';
        remark = estimations.gold_rate_remark
          ? `Remark: ${estimations.gold_rate_remark}`
          : 'You rejected this approval.';
        status = estimations.gold_rate_approval;
        type = 'error';
    }
  }
  return {
    title,
    message: remark,
    status,
    type,
  };
};

// -----

// When sales approves/rejects/pending Release final amount - For BRANCH

export const releasePaymentApprovalBranchMessage = (
  estimations: ReleaseFormValues,
  transaction: Transaction
) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  // not to show any alert message when payment completes
  if (estimations.payment_status == 'COMPLETED') {
    return {
      title,
      message: remark,
      status,
      type,
    };
  }

  // show as walked out if customer walks out
  if (transaction.status == 'WALKOUT') {
    return {
      title: 'Customer Walk-out',
      message: `Remark: ${transaction.walk_out_reason}`,
      type: 'error',
      status: transaction?.status,
    };
  }

  // IF sales team approves/rejects
  if (estimations.payment_sales_approval) {
    switch (estimations.payment_sales_approval) {
      case 'APPROVED':
        title = 'Sales Approved';
        remark = estimations.payment_sales_remark
          ? `Remark: ${estimations.payment_sales_remark}`
          : 'Sales has approved your request.';
        status = estimations.payment_sales_approval;
        type = 'info';
        break;

      case 'PENDING':
        title = 'Sales Pending';
        remark = estimations.payment_sales_remark
          ? `Remark: ${estimations.payment_sales_remark}`
          : 'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.';
        status = estimations.payment_sales_approval;
        type = 'warning';
        break;

      case 'REJECTED':
        title = 'Sales Rejected';
        remark = estimations.payment_sales_remark
          ? `Remark: ${estimations.payment_sales_remark}`
          : 'The Sales team has rejected the approval. Please mark the customer as having walked out.';
        status = estimations.payment_sales_approval;
        type = 'error';
    }
  }

  // IF sales head team approves/rejects
  if (estimations.payment_sales_head_approval) {
    switch (estimations.payment_sales_head_approval) {
      case 'APPROVED':
        title = 'Sales Head Approved';
        remark = estimations.payment_sales_head_remark
          ? `Remark: ${estimations.payment_sales_head_remark}`
          : 'Sales head has approved your request.';
        status = estimations.payment_sales_head_approval;
        type = 'info';
        break;

      case 'PENDING':
        title = 'Sales Head Pending';
        remark = estimations.payment_sales_head_remark
          ? `Remark: ${estimations.payment_sales_head_remark}`
          : 'Your transaction is currently being reviewed. Please check back once it has been approved by the sales head.';
        status = estimations.payment_sales_head_approval;
        type = 'warning';
        break;

      case 'REJECTED':
        title = 'Sales Head Rejected';
        remark = estimations.payment_sales_head_remark
          ? `Remark: ${estimations.payment_sales_head_remark}`
          : 'The sales head has rejected the approval. Please mark the customer as having walked out.';
        status = estimations.payment_sales_head_approval;
        type = 'error';
        break;
    }
  }

  return {
    title,
    message: remark,
    status,
    type,
  };
};

// When sales approves/rejects/pending Release final amount - For Sales
export const releasePaymentApprovalSalesMessage = (estimations: ReleaseFormValues) => {
  let title = null;
  let remark = null;
  let type = 'warning';
  let status = null;

  // sales message
  if (estimations.payment_sales_approval) {
    switch (estimations.payment_sales_approval) {
      case 'APPROVED':
        title = 'Approved';
        remark = estimations.payment_sales_remark
          ? `Remark: ${estimations.payment_sales_remark}`
          : 'You approved the request.';
        status = estimations.payment_sales_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Rejected';
        remark = estimations.payment_sales_remark
          ? `Remark: ${estimations.payment_sales_remark}`
          : 'You rejected this approval.';
        status = estimations.payment_sales_approval;
        type = 'error';
    }
  }

  // Sales head approval message
  if (estimations.payment_sales_head_approval) {
    switch (estimations.payment_sales_head_approval) {
      case 'APPROVED':
        title = 'Approved';
        remark = estimations.payment_sales_head_remark
          ? `Remark: ${estimations.payment_sales_head_remark}`
          : 'You approved the request.';
        status = estimations.payment_sales_head_approval;
        type = 'info';
        break;

      case 'REJECTED':
        title = 'Rejected';
        remark = estimations.payment_sales_head_remark
          ? `Remark: ${estimations.payment_sales_head_remark}`
          : 'You rejected this approval.';
        status = estimations.payment_sales_head_approval;
        type = 'error';
    }
  }

  return {
    title,
    message: remark,
    status,
    type,
  };
};

// When a branch submits the quotations to operations
export const quotationStatusHandler = (quotations: Quotation) => {
  let remark = null;
  if (quotations.status === 'PENDING') {
    return {
      title: 'Revaluation Pending',
      message:
        'This Quotation is in process. Please check back once it has been approved by the external team.',
      type: 'warning',
      status: quotations?.status,
    };
  } else if (quotations.status === 'REQUESTED') {
    remark = quotations.operations_remark
      ? quotations.operations_remark
      : 'Please retest the requested ornament';
    return {
      title: 'Re-test Requested by Operations',
      message: `Remark : ${remark}`,
      type: 'warning',
      status: quotations.status,
    };
  } else if (quotations.status === 'APPROVED') {
    remark = quotations.operations_remark
      ? quotations.operations_remark
      : 'The quotation has been approved by operations. This is the final quotation, and no further modifications can be made. Please switch off the Penny Drop tab to initiate the Penny Drop.';
    return {
      title: 'Quotation Approved',
      message: `Remark : ${remark}`,
      type: 'info',
      status: quotations.status,
    };
  }
  return {
    title: null,
    status: '',
    message: null,
    type: undefined,
  };
};

export const operationsStatusHandler = (quotations: Quotation) => {
  let remark = null;
  if (quotations.status == 'REQUESTED') {
    remark = quotations.operations_remark
      ? quotations.operations_remark
      : 'Request has been submitted to the branch Already, check back when branch sends the requested changes';
    return {
      title: 'Re-test Requested',
      message: `Remark : ${remark}`,
      type: 'warning',
      status: quotations.status,
    };
  } else if (quotations.status == 'APPROVED') {
    remark = quotations.operations_remark
      ? quotations.operations_remark
      : 'This Quotation has been already Approved';
    return {
      title: 'Quotation Approved',
      message: `Remark : ${remark}`,
      type: 'info',
      status: quotations.status,
    };
  }
  return {
    title: null,
    status: '',
    message: null,
    type: undefined,
  };
};

export const branchPledgeEstimationStatusHandlers = (
  transaction: Transaction,
  estimation: ReleaseFormValues
) => {
  if (transaction?.status === 'WALKOUT') {
    return {
      title: 'Customer Walk-out',
      message: transaction.walk_out_reason,
      type: 'error',
      status: transaction?.status,
    };
  }
  if (estimation.estimation_sales_approval) {
    switch (estimation.estimation_sales_approval) {
      case 'PENDING':
        return {
          title: 'Sales Approval Pending',
          status: estimation.estimation_sales_approval,
          message:
            'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.',
          type: 'warning',
        };

      case 'APPROVED':
        return {
          title: 'Sales Approved',
          status: estimation.estimation_sales_approval,
          message: `${
            estimation.estimation_sales_remark
              ? `Remark : ${estimation.estimation_sales_remark}`
              : 'Sales team has Approved'
          }`,
          type: 'info',
        };

      case 'REJECTED':
        return {
          title: 'Sales Rejected',
          status: estimation.estimation_sales_approval,
          message: `${
            estimation.estimation_sales_remark
              ? `Remark : ${estimation.estimation_sales_remark}`
              : 'Sales Team as Rejected the Approval. Please mark the customer as having walked out.'
          }`,
          type: 'error',
        };
    }
  }

  if (estimation.estimation_sales_head_approval) {
    switch (estimation.estimation_sales_head_approval) {
      case 'PENDING':
        return {
          title: 'Sales Head Approval Pending',
          status: estimation.estimation_sales_head_approval,
          message:
            'Your transaction is currently being reviewed. Please check back once it has been approved by the external team.',
          type: 'warning',
        };

      case 'APPROVED':
        return {
          title: 'Sales Head Approved',
          status: estimation.estimation_sales_head_approval,
          message: `${
            estimation.estimation_sales_head_remark
              ? `Remark : ${estimation.estimation_sales_head_remark}`
              : 'Sales Head has Approved'
          }`,
          type: 'info',
        };

      case 'REJECTED':
        return {
          title: 'Sales Head Rejected',
          status: estimation.estimation_sales_head_approval,
          message: `${
            estimation.estimation_sales_head_remark
              ? `Remark : ${estimation.estimation_sales_head_remark}`
              : 'Sales Head has Rejected the Approval. Please mark the customer as having walked out.'
          }`,
          type: 'error',
        };
    }
  }
  return {
    title: null,
    status: '',
    message: null,
    type: undefined,
  };
};

export const teleSalesStatusHandlers = (telesales: TeleBasicFormValues) => {
  if (telesales?.status === 'MERGED') {
    return {
      title: 'Lead Merged',
      message: 'This lead has been successfully merged with another lead. ',
      type: 'info',
    };
  }
  return {
    title: null,
    status: '',
    message: null,
    type: undefined,
  };
};
