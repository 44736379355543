import { Estimation } from 'src/interfaces';

import API from '..';
import { quotations } from '../entpoints';

export const createQuotation = async (esimationData: Estimation, transactionId: string) => {
  try {
    const formdata = new FormData();

    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // send deleted ids
    if (esimationData.deleted_ids?.length) {
      esimationData.deleted_ids.forEach((item) => {
        formdata.append(`deleted_ids[]`, item.toString());
      });
    }

    // gold rates
    formdata.append(`margin_24k`, esimationData.margin_24k?.toString() || '');
    formdata.append(`margin_22k`, esimationData.margin_22k?.toString() || '');
    formdata.append(`rate_17k_21k`, esimationData.rate_17k_21k?.toString() || '');
    formdata.append(`rate_14k_17k`, esimationData.rate_14k_17k?.toString() || '');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][ornament_id]`, ornament.ornament_id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(
        `ornaments[${index}][purity_percentage]`,
        ornament.purity_percentage?.toString() || ''
      );
      // const base64Data = ornament.spectrometer_image.replace(/^data:image\/\w+;base64,/, '');
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);
      formdata.append(`ornaments[${index}][spectrometer_image]`, ornament.spectrometer_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);

      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });

    const resp = await API.post(`${quotations.createQuotation}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateQuotation = async (
  esimationData: Estimation,
  transactionId: string,
  quotationId: string
) => {
  try {
    const formdata = new FormData();

    formdata.append(`transaction_id`, transactionId);
    formdata.append(`final_amount`, esimationData.final_amount?.toString());
    formdata.append(`service_charge_amount`, esimationData.service_charge_amount?.toString());
    formdata.append(`service_charge`, esimationData.service_charge?.toString());

    // gold rates
    formdata.append(`margin_24k`, esimationData.margin_24k?.toString() || '');
    formdata.append(`margin_22k`, esimationData.margin_22k?.toString() || '');
    formdata.append(`rate_17k_21k`, esimationData.rate_17k_21k?.toString() || '');
    formdata.append(`rate_14k_17k`, esimationData.rate_14k_17k?.toString() || '');

    // ornaments
    esimationData.ornaments.forEach((ornament, index) => {
      formdata.append(`ornaments[${index}][id]`, ornament.id || '');
      formdata.append(`ornaments[${index}][ornament_id]`, ornament.ornament_id || '');
      formdata.append(`ornaments[${index}][name]`, ornament.ornament_name);
      formdata.append(`ornaments[${index}][ornament_image]`, ornament.ornament_image);

      // const base64Data = ornament.spectrometer_image.replace(/^data:image\/\w+;base64,/, '');
      formdata.append(`ornaments[${index}][spectrometer_image]`, ornament.spectrometer_image);
      formdata.append(`ornaments[${index}][weight_image]`, ornament.weight_image);
      formdata.append(`ornaments[${index}][purity_image]`, ornament.purity_image);

      formdata.append(
        `ornaments[${index}][purity_percentage]`,
        ornament.purity_percentage?.toString() || ''
      );
      formdata.append(`ornaments[${index}][gold_rate]`, ornament.gold_rate?.toString() || '');
      formdata.append(`ornaments[${index}][gross_weight]`, ornament.gross_weight?.toString() || '');
      formdata.append(`ornaments[${index}][net_weight]`, ornament.net_weight?.toString());
      formdata.append(`ornaments[${index}][purity]`, ornament.purity?.toString());
      formdata.append(`ornaments[${index}][stone_weight]`, ornament.stone_weight?.toString());
      formdata.append(`ornaments[${index}][wastage]`, ornament.wastage?.toString() || '');
      formdata.append(`ornaments[${index}][branch_purity]`, ornament?.branch_purity);
      formdata.append(`ornaments[${index}][purity_value]`, ornament.purity_value?.toString());
      formdata.append(`ornaments[${index}][amount]`, ornament.amount?.toString() || '');
    });

    const resp = await API.patch(`${quotations.updateQuotations}/${quotationId}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getQuotations = async (
  status: string | undefined,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${quotations.index}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getQuotationsCount = async () => {
  try {
    const resp = await API.get(`${quotations.index}/count`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchQuotationById = async (quotationId: string) => {
  try {
    const resp = await API.get(`${quotations.index}/${quotationId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const quotationApproval = async (
  quotationId: string,
  ornament_ids: string[],
  approved: boolean,
  operations_remark?: string
) => {
  try {
    const request = {
      ornament_ids,
      approved,
      operations_remark,
    };
    const resp = await API.patch(`${quotations.index}/${quotationId}/operations_approval`, request);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
