// src/components/CustomDateRangePicker.tsx
// @ts-nocheck

import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import { Box, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';

interface CustomDateRangePickerProps {
  onDateChange: (dateRange: [Dayjs | null, Dayjs | null]) => void;
  initialDateRange?: [Dayjs | null, Dayjs | null];
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onDateChange,
  initialDateRange = [dayjs().startOf('day'), dayjs().endOf('day')],
}) => {
  const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>(initialDateRange);

  useEffect(() => {
    setValue(initialDateRange);
  }, [initialDateRange]);

  const handleChange: DateRangePickerProps<Dayjs, any>['onChange'] = (newValue) => {
    setValue(newValue);
    onDateChange(newValue);
  };

  return (
    <LocalizationProvider
      componentsProps={{
        actionBar: { actions: ['clear'], sx: { justifyContent: 'flex-start' } },
      }}
      dateAdapter={AdapterDayjs}
    >
        <DateRangePicker
          value={value}
          slotProps={{ field: { clearable: true } }}
          onChange={handleChange}
          localeText={{
            fieldDayPlaceholder: () => 'DD',
            fieldMonthPlaceholder: () => 'MMM',
            fieldYearPlaceholder: () => 'YYYY',
          }}
          format='DD-MMM-YYYY'
          renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
            <>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  {...startProps}
                  fullWidth
                />
              </Box>
              <Box sx={{ mx: 3 }}> to </Box>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  {...endProps}
                  fullWidth
                />
              </Box>
            </>
          )}
        />
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
