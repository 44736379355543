import { CreateTransactionParam, CustomerBasicFormValues } from 'src/interfaces';

import API from '..';
import { customer, transaction } from '../entpoints';

// create a new walkin
// TODO : move this function to transactions
export const createWalkin = async (body: CreateTransactionParam) => {
  try {
    const resp = await API.post(transaction.walkin, body);
    return resp;
  } catch (error) {
    throw error.response.data.message;
  }
};

// submit customer basic details
export const updateCustomerDetails = async (id: string, data: CustomerBasicFormValues) => {
  try {
    const resp = await API.patch(`${customer.customerUpdate}/${id}`, {
      ...data,
      location: data.location?.label,
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCustomerDetailsById = async (id: string) => {
  try {
    const resp = await API.get(`${customer.getDetailsById}/${id}`);
    return resp;
  } catch (error) {
    throw error.response.data.message;
  }
};